<template>
  <main class="mb-5 in-products">
    <section
      class="pages-path d-flex my-3 py-2 px-3 container bg-white box-shadow20 border-radius15 "
    >
      <svg
        class="ml-2"
        width="17"
        height="17"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
          stroke="#999"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        fill="currentColor"
        class="ml-2 mt-1 bi bi-chevron-double-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          fill="#a5a5a5"
        />
        <path
          fill-rule="evenodd"
          d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          fill="#a5a5a5"
        />
      </svg>
      <router-link to="/" class="fontsize12 text-color-666">خانه</router-link>
      <svg
        class="mx-2 mt-1-custom"
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="#a5a5a5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <router-link to="/products" class="fontsize11 text-color-a5"
        >محصولات</router-link
      >
      <svg
        v-if="hierarchyCategories.length"
        class="mx-2 mt-1-custom"
        width="12"
        height="12"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5 19L8.5 12L15.5 5"
          stroke="#a5a5a5"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div
        v-for="(category, index) in hierarchyCategories"
        :key="category.id"
        class="cat-breadcrump"
      >
        <router-link
          :to="'/products/category/' + category.id + '/' + category.slug"
          class="fontsize11 text-color-a5"
        >
          {{ category.name }}
        </router-link>
        <svg
          v-if="index + 1 !== hierarchyCategories.length"
          class="mx-sm-2 mx-1 mt-1-custom"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5 19L8.5 12L15.5 5"
            stroke="#a5a5a5"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </section>
    <hr class="w-100 container d-md-block d-none bg-color-fa" />
    <template v-if="category != null && category.parent_id == null">
      <section
        v-if="category.banner && category.show_banner"
        class="big-banner container mb-3"
      >
        <div class="d-md-none">
          <img :src="category.banner" :alt="category.title" />
        </div>
        <div class="main-big-banner">
          <img :src="category.banner" :alt="category.title" />
        </div>
      </section>
      <section
        v-if="special_products != ''"
        class="discounted-products discounted-products1 container  mb-4 mb-sm-5"
      >
        <div class="box-shadow20 p-3 border-radius15">
          <div class="d-flex justify-content-between  mb-1">
            <div class="mt-1">
              <img src="../../assets/images/circle.png" alt="" />
              <!-- <div class="circle1 rounded-circle mt-1 d-inline-block"></div> -->
              <h3 style="font-size: 1.25rem" class="d-inline-block">
                <span class="weight-bold text-color-444 pr-2">محصولات</span>
                <span
                  class="text-color-themeBlue fontsize15 font-weight-bolder weight-bold"
                  >ویژه</span
                >
              </h3>
            </div>
            <div class="d-flex mt-1">
              <button class="position-relative arrow-circle  rounded-circle d-block">
                <div class="swiper-button-next"></div>
              </button>
              <button
                class="position-relative arrow-circle   rounded-circle d-block mr-2"
              >
                <div class="swiper-button-prev"></div>
              </button>
            </div>
          </div>
          <div
            v-if="!disabledSliders.includes('discountProducts')"
            class="swiper-container py-3 px-3 mb-2"
          >
            <div class="swiper-wrapper">
              <div
                v-for="(item, index) in special_products"
                :key="index"
                class="swiper-slide discounted-products-item"
              >
                <product :new-tab="true" :product="item" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="
          child_categories != '' &&
            !disabledSliders.includes('productsCategories')
        "
        class="products-categories-swiper container mb-4 mb-sm-5"
      >
        <div class="px-2 px-md-0 swiper-container pb-5">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide px-1"
              v-for="cat in child_categories"
              :key="cat.id"
            >
              <div class="box-shaddow20 border-radius15 mt-2 mt-md-3">
                <router-link
                  class="w-100"
                  :to="'/products/category/' + cat.id + '/' + cat.slug"
                >
                  <img
                    :src="cat.image"
                    :alt="cat.title"
                    class="w-100 border-radius15"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <!-- <div class="row  px-2 px-md-0 ">
          <div class="col-md-3 col-6 px-1 px-md-3" v-for="cat in child_categories" :key="cat.id">
            <div class="box-shaddow20 border-radius15 mt-2 mt-md-3">
              <router-link class="w-100" :to="'/products/category/' + cat.id + '/' + cat.slug">
                <img :src="cat.image" :alt="cat.title" class="w-100 border-radius15">
              </router-link>
            </div>
          </div>
        </div> -->
      </section>
    </template>
    <div class="product-list-main-grid container text-right">
      <div class="welog-list-side d-lg-block d-none">
        <categories :categoryId="categoryId" />
        <!-- </section> -->
        <form @submit.prevent="searchItem(1, true)">
          <!-- <section class=" border-radius15 box-shaddow20 p-3 bg-white mb-3">
            <div class="d-flex justify-content-center border-bottom pb-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.12819 2H19.8718C21.0476 2 22 2.98105 22 4.19225V5.72376C22 6.31133 21.7704 6.87557 21.3627 7.28708L14.8577 13.867C14.7454 13.9816 14.5931 14.0452 14.4355 14.0441L8.98893 14.0272C8.82317 14.0272 8.66564 13.9561 8.55238 13.832L2.57452 7.25738C2.20489 6.85117 2 6.31451 2 5.7577V4.19332C2 2.98211 2.95238 2 4.12819 2ZM9.2801 15.8241L14.1347 15.839C14.4374 15.8401 14.6824 16.0935 14.6824 16.4043V19.1353C14.6824 19.4471 14.5053 19.7293 14.2294 19.8597L9.8227 21.9289C9.71974 21.9767 9.61061 22 9.50147 22C9.35629 22 9.21112 21.9576 9.08448 21.8738C8.86311 21.7274 8.72927 21.475 8.72927 21.2046V16.3894C8.72927 16.0766 8.97637 15.8231 9.2801 15.8241Z"
                  fill="#444"
                />
              </svg>
              <h6 class="mr-2 fontsize13 weight-bold text-color-444">
                فیلتر جستجو...
              </h6>
            </div>
            <h6 class="mr-2 fontsize13 weight-bold text-color-444 mt-3 mb-3">
              عنوان
            </h6>
            <input
              type="text"
              placeholder="عنوان جستجو را بنویس..."
              v-model="searchTitle"
              class=" py-2 px-3  border w-100 fontsize12"
              style="border-radius: 5px"
            />
          </section> -->

          <section
            v-if="brands"
            class="border-radius15 box-shaddow20 p-3 bg-white mb-3"
          >
            <div class="text-right border-bottom pb-2">
              <h6 class="mr-2 fontsize13 weight-bold text-color-444">
                انتخاب برند
              </h6>
            </div>
            <b-form-group class="mt-3">
              <multiselect
                :allow-empty="true"
                class="text-right"
                v-model="brand"
                :options="brands"
                track-by="id"
                label="name"
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                placeholder="انتخاب کنید..."
              >
              </multiselect>
            </b-form-group>
          </section>

          <section class="border-radius15 box-shaddow20 p-3 bg-white mb-3">
            <div class="text-right border-bottom pb-2">
              <h6 class="mr-2 fontsize13 weight-bold text-color-444">
                حالت نمایش
              </h6>
            </div>
            <b-form-group class="mt-3">
              <b-form-checkbox
                switch
                v-model="searchAvailable"
                id="status"
                size="lg"
                class=" d-inline-block "
              >
              </b-form-checkbox>
              <label for="status" class="mb-0 mr-2 fontsize12 text-color-666">
                فقط کالاهای موجود
              </label>
            </b-form-group>
          </section>
          <section class="border-radius15 box-shaddow20 p-3 bg-white mb-3">
            <h6 class="mr-2 fontsize13 weight-bold text-color-444">قیمت ها</h6>
            <vue-slider
              class=" mx-2 my-4"
              ref="slider"
              v-model="priceRange"
              :tooltip="'none'"
              :max="
                $store.state.header.minMax > 5000000
                  ? $store.state.header.minMax.max
                  : 5000000
              "
              :interval="step"
              direction="rtl"
            >
            </vue-slider>
            <div
              class="fontsize12 d-flex flex-wrap justify-content-between weight-bold"
            >
              <div class="ml-3">
                <span class="text-color-444 ml-1 "> قیمت : از</span>
                <span class="text-color-666" v-if="priceRange[0] != 0"
                  >{{ priceRange[0] | price }} تومان</span
                >
                <span class="text-color-666" v-else>0 تومان</span>
              </div>
              <div>
                <span class="text-color-444 ml-1 ">تا</span>
                <span class="text-color-666"
                  >{{ priceRange[1] | price }} تومان</span
                >
              </div>
            </div>
          </section>

          <button
            type="submit"
            :disabled="disabled"
            class="fontsize13 weight-bold text-white bg-color-themeBlue py-2 px-3 w-100 border-radius10"
          >
            اعمال فیلتر
          </button>
          <button
            @click="removeFilter"
            type="button"
            :disabled="disabled"
            class="fontsize13 weight-bold text-white py-2 px-3 w-100 border-radius10 mt-2"
            style="background: #f6c989"
          >
            حذف فیلتر ها
          </button>
        </form>
      </div>
      <div class="weblog-list-main ">
        <section
          class="filter-buttons d-flex flex-wrap justify-content-between mb-3 d-lg-none"
        >
          <button
            class="d-flex justify-content-center bg-color-ea py-3 px-3 border-radius15 mb-2 w-49"
            data-toggle="modal"
            data-target="#productFilterModal"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.12819 2H19.8718C21.0476 2 22 2.98105 22 4.19225V5.72376C22 6.31133 21.7704 6.87557 21.3627 7.28708L14.8577 13.867C14.7454 13.9816 14.5931 14.0452 14.4355 14.0441L8.98893 14.0272C8.82317 14.0272 8.66564 13.9561 8.55238 13.832L2.57452 7.25738C2.20489 6.85117 2 6.31451 2 5.7577V4.19332C2 2.98211 2.95238 2 4.12819 2ZM9.2801 15.8241L14.1347 15.839C14.4374 15.8401 14.6824 16.0935 14.6824 16.4043V19.1353C14.6824 19.4471 14.5053 19.7293 14.2294 19.8597L9.8227 21.9289C9.71974 21.9767 9.61061 22 9.50147 22C9.35629 22 9.21112 21.9576 9.08448 21.8738C8.86311 21.7274 8.72927 21.475 8.72927 21.2046V16.3894C8.72927 16.0766 8.97637 15.8231 9.2801 15.8241Z"
                fill="#444"
              />
            </svg>
            <span class="mr-2 fontsize12 weight-bold text-color-444"
              >فیلتر جستجو...</span
            >
          </button>
          <button
            class="d-flex justify-content-center bg-color-ea py-3 px-3 border-radius15 mb-2 w-49"
            data-toggle="modal"
            data-target="#productOrderModal"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.1172 17.9866H2.88306"
                stroke="#444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.1179 17.9866C21.1179 19.5772 19.8285 20.8666 18.2379 20.8666C16.6473 20.8666 15.3579 19.5772 15.3579 17.9866C15.3579 16.3948 16.6473 15.1066 18.2379 15.1066C19.8285 15.1066 21.1179 16.3948 21.1179 17.9866Z"
                stroke="#444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.8827 6.26212H21.118"
                stroke="#444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.8826 6.26211C2.8826 7.85388 4.17201 9.14211 5.7626 9.14211C7.35319 9.14211 8.6426 7.85388 8.6426 6.26211C8.6426 4.67152 7.35319 3.38211 5.7626 3.38211C4.17201 3.38211 2.8826 4.67152 2.8826 6.26211Z"
                stroke="#444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="mr-2 fontsize12 weight-bold text-color-444">
              ترتیب نمایش براساس...</span
            >
          </button>
        </section>

        <div
          class="productFilterModal modal fade"
          id="productFilterModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header border-bottom-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body px-sm-5 px-3 pb-sm-5 pb-3">
                <div class="welog-list-side">
                  <form @submit.prevent="searchItem(1, true)">
                    <section
                      class=" border-radius15 box-shaddow20 p-3 bg-white mb-3"
                    >
                      <div
                        class="d-flex justify-content-center border-bottom pb-2"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.12819 2H19.8718C21.0476 2 22 2.98105 22 4.19225V5.72376C22 6.31133 21.7704 6.87557 21.3627 7.28708L14.8577 13.867C14.7454 13.9816 14.5931 14.0452 14.4355 14.0441L8.98893 14.0272C8.82317 14.0272 8.66564 13.9561 8.55238 13.832L2.57452 7.25738C2.20489 6.85117 2 6.31451 2 5.7577V4.19332C2 2.98211 2.95238 2 4.12819 2ZM9.2801 15.8241L14.1347 15.839C14.4374 15.8401 14.6824 16.0935 14.6824 16.4043V19.1353C14.6824 19.4471 14.5053 19.7293 14.2294 19.8597L9.8227 21.9289C9.71974 21.9767 9.61061 22 9.50147 22C9.35629 22 9.21112 21.9576 9.08448 21.8738C8.86311 21.7274 8.72927 21.475 8.72927 21.2046V16.3894C8.72927 16.0766 8.97637 15.8231 9.2801 15.8241Z"
                            fill="#444"
                          />
                        </svg>
                        <h6 class="mr-2 fontsize13 weight-bold text-color-444">
                          فیلتر جستجو...
                        </h6>
                      </div>
                      <h6
                        class="mr-2 fontsize13 weight-bold text-color-444 mt-3"
                      >
                        عنوان
                      </h6>
                      <input
                        type="text"
                        placeholder="عنوان جستجو را بنویس..."
                        class=" py-2 px-3 border border-radius10 w-100 fontsize12"
                      />
                    </section>
                    <section
                      class="border-radius15 box-shaddow20 p-3 bg-white mb-3"
                    >
                      <div class="text-right border-bottom pb-2">
                        <h6 class="mr-2 fontsize13 weight-bold text-color-444">
                          حالت نمایش
                        </h6>
                      </div>
                      <b-form-group class="mt-3 py-2">
                        <b-form-checkbox
                          switch
                          v-model="searchAvailable"
                          id="status"
                          size="lg"
                          class=" d-inline-block "
                        >
                        </b-form-checkbox>
                        <label
                          for="status"
                          class="mb-0 mr-2 fontsize12 text-color-666"
                        >
                          فقط کالاهای موجود
                        </label>
                      </b-form-group>
                    </section>
                    <section
                      class="border-radius15 box-shaddow20 p-3 bg-white mb-3"
                    >
                      <h6 class="mr-2 fontsize13 weight-bold text-color-444">
                        قیمت ها
                      </h6>
                      <vue-slider
                        class="mt-1 mx-2"
                        ref="slider"
                        v-model="priceRange"
                        :tooltip="'none'"
                        :max="
                          $store.state.header.minMax > 5000000
                            ? $store.state.header.minMax.max
                            : 5000000
                        "
                        :interval="step"
                        direction="rtl"
                      >
                      </vue-slider>
                      <div
                        class="fontsize12 d-flex flex-wrap justify-content-between"
                      >
                        <div class="ml-3">
                          <span class="text-color-444 ml-1">از قیمت:</span>
                          <span class="text-color-666" v-if="priceRange[0] != 0"
                            >{{ priceRange[0] | price }} تومان</span
                          >
                          <span class="text-color-666" v-else>0 تومان</span>
                        </div>
                        <div>
                          <span class="text-color-444 ml-1">تا</span>
                          <span class="text-color-666"
                            >{{ priceRange[1] | price }} تومان</span
                          >
                        </div>
                      </div>
                    </section>
                    <button
                      type="submit"
                      :disabled="disabled"
                      class="fontsize13 weight-bold text-white bg-color-themeBlue py-2 px-3 w-100 border-radius10"
                    >
                      اعمال فیلتر
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="productFilterModal modal fade"
          id="productOrderModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header border-bottom-0">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body px-sm-5 px-3 pb-sm-5 pb-3">
                <section
                  class="product-view-order d-flex justify-content-between align-items-baseline flex-wrap  bg-white box-shaddow20 border-radius15 p-3 mb-3"
                >
                  <div class="order-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-filter"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                        fill="#999"
                      />
                    </svg>
                    <span class="text-color-666 fontsize12 weight-bold"
                      >ترتیب نمایش:</span
                    >
                  </div>
                  <div
                    class="d-flex flex-wrap justify-content-xl-between fontsize12 order-xl-1 order-2"
                  >
                    <button
                      :disabled="sort == 'most_visited' ? true : false"
                      @click="
                        (sort = 'most_visited'),
                          (discount = false),
                          searchItem()
                      "
                      type="button"
                      :class="
                        sort == 'most_visited'
                          ? 'sort-active'
                          : 'text-color-999'
                      "
                      class="ml-1 py-2 px-3  border-radius10"
                    >
                      پربازدید ترین
                    </button>
                    <button
                      :disabled="sort == 'low_to_high' ? true : false"
                      :class="
                        sort == 'low_to_high' ? 'sort-active' : 'text-color-999'
                      "
                      @click="
                        (sort = 'low_to_high'), (discount = false), searchItem()
                      "
                      type="button"
                      class="ml-1 py-2 px-3 border-radius10"
                    >
                      ارزان ترین
                    </button>
                    <button
                      :disabled="sort == 'high_to_low' ? true : false"
                      :class="
                        sort == 'high_to_low' ? 'sort-active' : 'text-color-999'
                      "
                      @click="
                        (sort = 'high_to_low'), (discount = false), searchItem()
                      "
                      type="button"
                      class="ml-1 py-2 px-3  border-radius10"
                    >
                      گران ترین
                    </button>
                    <button
                      :disabled="sort == 'top_sales' ? true : false"
                      :class="
                        sort == 'top_sales' ? 'sort-active' : 'text-color-999'
                      "
                      @click="
                        (sort = 'top_sales'), (discount = false), searchItem()
                      "
                      type="button"
                      class="ml-1 py-2 px-3 border-radius10"
                    >
                      پرفروش ترین
                    </button>
                    <button
                      :disabled="sort == 'newest' ? true : false"
                      :class="
                        sort == 'newest' ? 'sort-active' : 'text-color-999'
                      "
                      @click="
                        (sort = 'newest'), (discount = false), searchItem()
                      "
                      type="button"
                      class="ml-1 py-2 px-3 border-radius10"
                    >
                      جدید ترین
                    </button>
                    <button
                      :disabled="discount ? true : false"
                      :class="discount ? 'sort-active' : 'text-color-999'"
                      @click="(discount = true), (sort = ''), searchItem()"
                      type="button"
                      class="ml-1 py-2 px-3 border-radius10"
                    >
                      ویژه
                    </button>
                  </div>
                  <span
                    class="fontsize12 text-color-666 order-xl-2 order-1 weight-bold"
                  >
                    <span class="pl-2">{{
                      products ? products.total : 0
                    }}</span>
                    <span>کالا</span>
                  </span>
                </section>
              </div>
            </div>
          </div>
        </div>

        <section
          id="product-view-order"
          class="product-view-order d-lg-flex d-none justify-content-between align-items-baseline flex-wrap  bg-white box-shaddow20 border-radius15 p-3 mb-3"
        >
          <div class="order-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-filter"
              viewBox="0 0 16 16"
            >
              <path
                d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                fill="#999"
              />
            </svg>
            <span class="text-color-666 fontsize12 weight-bold"
              >ترتیب نمایش:</span
            >
          </div>
          <div
            class="d-flex flex-wrap justify-content-xl-between fontsize12 order-xl-1 order-2"
          >
            <button
              :disabled="sort == 'most_visited' ? true : false"
              :class="sort == 'most_visited' ? 'sort-active' : 'text-color-999'"
              @click="(sort = 'most_visited'), (discount = false), searchItem()"
              type="button"
              class="ml-1 py-2 px-3 text-color-999 border-radius10"
            >
              پربازدید ترین
            </button>
            <button
              :disabled="sort == 'low_to_high' ? true : false"
              :class="sort == 'low_to_high' ? 'sort-active' : 'text-color-999'"
              @click="(sort = 'low_to_high'), (discount = false), searchItem()"
              type="button"
              class="ml-1 py-2 px-3 text-color-999 border-radius10"
            >
              ارزان ترین
            </button>
            <button
              :disabled="sort == 'high_to_low' ? true : false"
              :class="sort == 'high_to_low' ? 'sort-active' : 'text-color-999'"
              @click="(sort = 'high_to_low'), (discount = false), searchItem()"
              type="button"
              class="ml-1 py-2 px-3 text-color-999 border-radius10"
            >
              گران ترین
            </button>
            <button
              :disabled="sort == 'top_sales' ? true : false"
              :class="sort == 'top_sales' ? 'sort-active' : 'text-color-999'"
              @click="(sort = 'top_sales'), (discount = false), searchItem()"
              type="button"
              class="ml-1 py-2 px-3 text-color-999 border-radius10"
            >
              پرفروش ترین
            </button>
            <button
              :disabled="sort == 'newest' ? true : false"
              :class="sort == 'newest' ? 'sort-active' : 'text-color-999'"
              @click="(sort = 'newest'), (discount = false), searchItem()"
              type="button"
              class="ml-1 py-2 px-3 text-color-999 border-radius10"
            >
              جدید ترین
            </button>
            <button
              :disabled="discount ? true : false"
              :class="discount ? 'sort-active' : 'text-color-999'"
              @click="(discount = true), (sort = ''), searchItem()"
              type="button"
              class="ml-1 py-2 px-3 text-color-999 border-radius10"
            >
              ویژه
            </button>
          </div>
          <span class="fontsize13 text-color-666 order-xl-2 order-1">
            <span class="weight-bold">
              {{ products ? products.total : 0 }}</span
            >
            <span class="pr-1 weight-bold">کالا</span>
          </span>
        </section>
        <section class="products-list-grid px-md-3 px-0">
          <article
            v-for="(item, index) in products.data"
            :key="index"
            class="bg-white text-center position-relative discounted-products-item"
          >
            <product :new-tab="true" :product="item" :comparable="true" />
          </article>
        </section>
        <section
          v-if="products.data == ''"
          class="bg-white-90 text-center border-radius15 box-shaddow20"
        >
          <h5 class="font-weight-bold fontsize17 text-color-666  py-5">
            محصولی یافت نشد.
          </h5>
        </section>
        <section
          v-if="products.data && !search"
          class="list-pagination text-center my-4 p-2 "
        >
          <pagination
            :limit="2"
            :data="products"
            @pagination-change-page="loadItems"
            :disabled="disabled"
            class="justify-content-center"
          >
            <span slot="prev-nav">
              <svg
                class="page-arrow mb-2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="22"
                height="22"
                viewBox="0 0 226 226"
                style=" fill:#000000;"
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <path d="M0,226v-226h226v226z" fill="none"></path>
                  <g fill="#b2b2b2">
                    <path
                      d="M82.80781,169.67656c1.05938,1.05937 2.47187,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906l52.96875,-52.96875c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-52.96875,-52.96875c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0c-2.11875,2.11875 -2.11875,5.47344 0,7.41563l49.26094,49.26094l-49.26094,49.26094c-2.11875,2.11875 -2.11875,5.29688 0,7.41562z"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
            <span slot="next-nav">
              <svg
                class="page-arrow"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="22"
                height="22"
                viewBox="0 0 226 226"
                style=" fill:#000000;"
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <path d="M0,226v-226h226v226z" fill="none"></path>
                  <g fill="#b2b2b2">
                    <path
                      d="M143.19219,56.32344c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0l-52.96875,52.96875c-2.11875,2.11875 -2.11875,5.47344 0,7.41562l52.96875,52.96875c1.05937,1.05937 2.47188,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-49.26094,-49.26094l49.26094,-49.26094c2.11875,-2.11875 2.11875,-5.29687 0,-7.41563z"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
          </pagination>
        </section>
        <section
          v-if="products.data && search"
          class="list-pagination text-center my-4 p-2 "
        >
          <pagination
            :limit="2"
            :data="products"
            @pagination-change-page="searchItem"
            :disabled="disabled"
            class="justify-content-center"
          >
            <span slot="prev-nav">
              <svg
                class="page-arrow mb-2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="22"
                height="22"
                viewBox="0 0 226 226"
                style=" fill:#000000;"
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <path d="M0,226v-226h226v226z" fill="none"></path>
                  <g fill="#b2b2b2">
                    <path
                      d="M82.80781,169.67656c1.05938,1.05937 2.47187,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906l52.96875,-52.96875c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-52.96875,-52.96875c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0c-2.11875,2.11875 -2.11875,5.47344 0,7.41563l49.26094,49.26094l-49.26094,49.26094c-2.11875,2.11875 -2.11875,5.29688 0,7.41562z"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
            <span slot="next-nav">
              <svg
                class="page-arrow"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="22"
                height="22"
                viewBox="0 0 226 226"
                style=" fill:#000000;"
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <path d="M0,226v-226h226v226z" fill="none"></path>
                  <g fill="#b2b2b2">
                    <path
                      d="M143.19219,56.32344c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0l-52.96875,52.96875c-2.11875,2.11875 -2.11875,5.47344 0,7.41562l52.96875,52.96875c1.05937,1.05937 2.47188,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-49.26094,-49.26094l49.26094,-49.26094c2.11875,-2.11875 2.11875,-5.29687 0,-7.41563z"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
          </pagination>
        </section>
        <!-- <section class="d-flex flex-wrap justify-content-between mb-3 d-lg-none">
            <button class="d-flex justify-content-center bg-color-ea p-sm-3 p-2 border-radius15 mb-2 w-49">
                <span class="mr-2 fontsize11 weight-bold text-color-444">صفحه قبلی</span>
            </button>
            <button class="d-flex justify-content-center bg-color-ea p-sm-3 p-2 border-radius15 mb-2 w-49">
                <span class="mr-2 fontsize11 weight-bold text-color-444"> صفحه بعدی</span>
            </button>
        </section> -->
      </div>
    </div>
  </main>
</template>
<script>
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.css";
import product from "./component/product.vue";
import categories from "./component/categories/categories.vue";

export default {
  components: {
    product,
    categories,
  },
  data() {
    return {
      url: "/api/products",
      products: {
        data: Array(6).fill(null),
      },
      categoryId: null,
      disabled: false,
      searchTitle: "",
      search: false,
      searchAvailable: 0,
      priceRange: [0, 5000000],
      step: 1000,
      hasFilter: false,
      sort: "",
      discount: false,
      special_products: [],
      category: null,
      brand: null,
      child_categories: [],
    };
  },
  watch: {
    brands: {
      handler: function(brands) {
        this.$nextTick(() => {
          if (brands && this.brand && !this.brand.name) {
            this.brand = this.brands.find((b) => b.id == this.brand.id);
          }
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    hierarchyCategories() {
      if (!this.category) {
        return [];
      }
      if (!this.category.all_parent) {
        return [this.category];
      }
      let parents = this.loadCategoryParent(this.category, [this.category]);

      return parents.reverse();
    },
    // minPrice() {
    //     return this.priceRange[0]
    // },
    // maxPrice() {
    //     return this.priceRange[1]
    // },
    brands() {
      return this.$store.state.header ? this.$store.state.header.brands : [];
    },
  },
  methods: {
    loadCategoryParent(cat, parents = []) {
      let parent = cat.all_parent;
      if (parent) {
        parents.push(parent);
        parents = this.loadCategoryParent(parent, parents);
      }
      return parents;
    },
    init() {
      this.$("#categoriesModal").modal("hide");
      this.categoryId = this.$route.params.category;
      this.searchTitle = this.$route.query.title || "";
      this.brand = this.$route.query.brand
        ? { id: this.$route.query.brand }
        : null;

      if (
        this.$route.query.discount ||
        this.$route.query.sort ||
        this.$route.query.title ||
        this.brand
      ) {
        this.$route.query.discount
          ? (this.discount = true)
          : (this.discount = false);
        this.$route.query.sort ? (this.sort = this.$route.query.sort) : "";
        this.searchItem();
        // this.$route.query.title ? this.searchTitle = this.$route.query.title : ''
      } else {
        this.loadItems();
      }
      if (
        this.$store.state.header.minMax &&
        this.$store.state.header.minMax.max > 5000000
      ) {
        this.priceRange[1] = this.$store.state.header.minMax.max;
      }
    },
    removeFilter() {
      let initData = this.$options.data.apply(this);
      for (let [index, value] of Object.entries(initData)) {
        this[index] = value;
      }
      this.init();
    },
    loadItems(page = 1) {
      this.disabled = true;
      let data = {
        page: page,
      };
      if (this.categoryId) {
        data.category_id = this.categoryId;
      }
      this.$axios
        .get(this.url, {
          params: data,
        })
        .then((response) => {
          const data = response.data.data;
          this.products = data.products;
          this.special_products = data.special_products;
          this.category = data.category;
          this.child_categories = data.child_categories;
          this.initDiscountProducts(true);
          this.initProductsCategories(true);
          this.$store.commit("header/setCategoryTitle", this.category.name);
          this.$root.scrollTo("main");
          if ("scrollRestoration" in window.history) {
            window.history.scrollRestoration = "manual";
          }
          setTimeout(function() {
            document
              .getElementById("product-view-order")
              .scrollIntoView({ behavior: "smooth" });
          }, 10);
        })
        .catch(function(response) {
          //handle error
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    searchItem(page = 1, fromButton = false) {
      this.disabled = true;
      this.search = true;
      let data = {
        page: page,
      };
      if (this.categoryId) {
        data.category_id = this.categoryId;
      }
      if (this.discount) {
        data.discount = true;
      }
      if (this.sort != "") {
        data.sort = this.$route.query.sort
          ? this.$route.query.sort
          : this.sort
          ? this.sort
          : "";
      }
      if (fromButton || this.hasFilter) {
        data.available = this.searchAvailable ? 1 : 0;
        this.hasFilter = true;
        data.min_price = this.priceRange[0];
        data.max_price = this.priceRange[1];
      }
      if (this.brand) {
        data.brand_id = this.brand.id;
      }
      if (this.searchTitle) {
        this.url = "/api/products-search-result?title=" + this.searchTitle;
      }
      this.$axios
        .get(this.url, {
          params: data,
        })
        .then((response) => {
          const data = response.data.data;
          this.products = data.products;
          this.special_products = data.special_products;
          this.category = data.category;
          this.child_categories = data.child_categories;
          this.initDiscountProducts(true);
          this.$root.scrollTo("main");
        })
        .catch(function(response) {
          //handle error
        })
        .finally(() => {
          this.disabled = false;
        });

      this.$("#productFilterModal").modal("hide");
      this.$("#productOrderModal").modal("hide");
    },
    togglePlus(e) {
      let el = e.currentTarget;
      el.children.forEach((child) => {
        window.child = child;
        if (child.classList.contains("openclose")) {
          console.log(child.innerHTML);
          if (child.innerHTML === "+") {
            child.innerHTML = "—";
          } else {
            child.innerHTML = "+";
          }
        }
      });
    },
    initDiscountProducts(kill = false) {
      this.initSwiper("discountProducts", kill, () => {
        new Swiper(".discounted-products1 .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: false,
          speed: 800,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: ".discounted-products1 .swiper-button-next",
            prevEl: ".discounted-products1 .swiper-button-prev",
          },
          breakpoints: {
            1: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            570: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            571: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          },
        });
      });
    },
    initProductsCategories(kill = false) {
      this.initSwiper("productsCategories", kill, () => {
        new Swiper(".products-categories-swiper .swiper-container", {
          spaceBetween: 30,
          //   centeredSlides: true,
          loop: false,
          speed: 800,
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          // navigation: {
          //   nextEl: '.productsCategories .swiper-button-next',
          //   prevEl: '.productsCategories .swiper-button-prev',
          // },
          pagination: {
            el: ".products-categories-swiper .swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            400: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            571: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          },
        });
      });
    },
  },
};
</script>
<style>
.in-products .discounted-products-img img {
  height: 195px;
  width: 195px;
}

.in-products .products-list-grid .discounted-products-img {
  height: 192px !important;
  width: 100%;
}

.products-list-filter .card-header > .category-active::before,
.products-list-filter .card-body .category-active::before {
  background-color: #713348;
}

/* .in-products .custom-switch .custom-control-label::after {
    background-color: var(--color-themeBlue);
} */
.in-products .custom-switch.b-custom-control-lg .custom-control-label::after,
.in-products .input-group-lg .custom-switch .custom-control-label::after {
  width: 20px;
  height: 20px;
  background-color: #999;
  top: 3px;
  left: calc(-2.8125rem);
}

.in-products .custom-switch.b-custom-control-lg .custom-control-label::before,
.in-products .input-group-lg .custom-switch .custom-control-label::before {
  height: 17px;
}

.in-products .custom-control-label::before {
  background-color: #ddd;
  border: unset;
}

.in-products .custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(169, 169, 247);
}

.in-products .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: unset;
}

.in-products
  .custom-switch.b-custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after,
.in-products
  .input-group-lg
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: var(--color-themeBlue);
}

.in-products .sort-active {
  color: #333;
  /* border: 1px solid var(--color-themeBlue); */
  background-color: var(--color-eai);
}
.in-products .products-categories-swiper .swiper-pagination-bullets {
  bottom: 0;
}
@media screen and (max-width: 991px) {
  .in-products .discounted-products-img img {
    /*height: 247px;*/
    /*width: 247px;*/
    height: 176px;
    width: 176px;
  }

  .in-products .products-list-grid .discounted-products-img {
    height: 173px !important;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .in-products .products-list-grid .discounted-products-img {
    height: 160px !important;
    width: auto;
  }
  .in-products .discounted-products-img img {
    height: 147px;
    width: 147px;
  }
}
.in-products .multiselect__option--highlight {
  background: #7570b3;
}

.in-products .multiselect {
  min-height: 35px;
}

.in-products .multiselect__tags {
  min-height: 35px;
  padding: 2px 40px 0 8px;
}

.in-products .multiselect__placeholder {
  margin-bottom: 5px;
  padding-top: 5px;
  font-size: 13px;
}

.in-products .multiselect__input,
.multiselect__single {
  min-height: 15px;
  line-height: 28px;
}

.in-products .multiselect__single {
  margin-bottom: 0 !important;
}

.cat-breadcrump {
  display: contents;
  /*transform: translateY(-4px);*/
}

.mt-1-custom {
  margin-top: 3px;
}
</style>
