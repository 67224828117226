<template>
    <section class="products-list-filter border-radius15 box-shaddow20 p-3 text-right bg-white mb-3">
        <h6 class="mr-2 fontsize13 weight-bold text-color-444 mt-3">دسته بندی ها<span v-if="title != null" class="fontsize13 weight-bold text-color-444 ">{{ 'ی ' + title}}</span></h6>
    <div class="accordion mt-3" id="accordionProductFilter">
        <template v-if="$store.state.header.categories != ''">
            <div class="card" v-for="(category, index) in $store.state.header.categories" :key="index">
                <div class="card-header d-flex justify-content-between p-0" :id="'heading' + category.id">
                    <router-link :to="category ? '/products/category/' + category.id + '/' + category.slug : ''"
                        class="fontsize12 text-color-666 my-auto"
                        :class="category.id == categoryId ? 'category-active' : ''">
                        {{ category.name }}
                    </router-link>
                    <button @click="togglePlus" :aria-expanded="categoryActive(category)" v-if="category.children != ''"
                        class="all-categories-side-btn collapsed px-3 py-1" type="button" data-toggle="collapse"
                        :data-target="'#collapse' + category.id" :aria-controls="'collapse' + category.id">
                        <span
                            class="openclose fontsize15 text-color-666">{{ categoryActive(category) ? '—' : '+' }}</span>
                    </button>
                </div>
                <div v-if="category.children != []" :id="'collapse' + category.id"
                    :class="categoryActive(category) ? 'collapse show' : 'collapse'"
                    :aria-labelledby="'heading' + category.id" data-parent="#accordionProductFilter">
                    <div class="card-body">
                        <ul class="list-unstyled fontsize12">
                            <li v-for="item in category.children" :key="item.id">
                                <div class="card-header d-flex justify-content-between p-0"
                                    :id="'heading-category' + item.id">
                                    <router-link class="px-2" :class="item.id == categoryId ? 'category-active' : ''"
                                        :to="category ? '/products/category/' + item.id + '/' + item.slug : ''">
                                        {{ item.name }}
                                    </router-link>
                                    <button @click="togglePlus" :aria-expanded="categoryActive(item)"
                                        v-if="item.children != ''"
                                        class="all-categories-side-btn collapsed px-3 py-1" type="button"
                                        data-toggle="collapse" :data-target="'#collapse-category' + item.id"
                                        :aria-controls="'collapse-category' + item.id">
                                        <span
                                            class="openclose fontsize15 text-color-666">{{ categoryActive(item) ? '—' : '+' }}</span>
                                    </button>
                                </div>
                                <div v-if="category.children != []" :id="'collapse-category' + item.id"
                                    :class="categoryActive(item) ? 'collapse show' : 'collapse'"
                                    :aria-labelledby="'heading-category' + item.id">
                                    <div class="card-body">
                                        <ul class="list-unstyled fontsize12">
                                            <li v-for="subItem in item.children" :key="subItem.id">
                                                <router-link class="px-2"
                                                    :class="subItem.id == categoryId ? 'category-active' : ''"
                                                    :to="item ? '/products/category/' + subItem.id + '/' + subItem.slug : ''">
                                                    {{ subItem.name }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <b-skeleton class="mx-4"></b-skeleton>
            <b-skeleton class="mx-4"></b-skeleton>
            <b-skeleton class="mx-4"></b-skeleton>
        </template>
    </div>
    </section>
</template>
<script>
    export default {
        props: ['categoryId' , 'title' ],
        methods: {
            categoryActive(category) {
                let flag = false
                if (category.id == this.categoryId && category.children.length) {
                    flag = true
                    this.categoryTitle = category.title
                }
                category.children.forEach(childCategory => {
                    if (childCategory.id == this.categoryId) {
                        flag = true
                    }
                    childCategory.children.forEach(childChildCategory => {
                      if (childChildCategory.id == this.categoryId) {
                        flag = true
                      }
                    })
                });
                return flag
            },
            togglePlus(e) {
                let el = e.currentTarget
                el.children.forEach((child) => {
                    window.child = child
                    if (child.classList.contains('openclose')) {
                        // console.log(child.innerHTML);
                        if (child.innerHTML === '+') {
                            child.innerHTML = '—'
                        } else {
                            child.innerHTML = '+'
                        }
                    }
                })
            }
        }
    }
</script>